import  create from "../uril/requwst";
//连接
export function ping(data){
    //get 请求=》params
    return create({
        url:"/func/ping/",
        method:"post",
        data
    })
}
//获取用户作业批改历史
export function workhistoryget(params){
    //get 请求=》params
    return create({
        url:"work/workhistory/",
        method:"get",
        params:params
    })
}
//获取用户作业批改历史
export function map(params){
    //get 请求=》params
    return create({
        url:"work/map/",
        method:"get",
        params:params
    })
}

//删除作文批改历史
export function workhistorydelete(data){
    //get 请求=》params
    return create({
        url:"/work/workhistory/",
        method:"delete",
        data
    })
}
//查看作文批改详情
export function workxqget(params){
    //get 请求=》params
    return create({
        url:"work/workxq/",
        method:"get",
        params:params
    })
}
//历史
export function historyget(params){
    //get 请求=》params
    return create({
        url:"/his/history/",
        method:"get",
        params:params
    })
}
//删除history 数据
export function historydelete(data){
    //get 请求=》params
    return create({
        url:"/his/history/",
        method:"delete",
        data
    })
}
//添加书签
export function bookmarkpost(data){
    //get 请求=》params
    return create({
        url:"/his/history/book/",
        method:"post",
        data
    })
}
//删除书签
export function bookmarkdelete(data){
    //get 请求=》params
    return create({
        url:"/his/history/book/",
        method:"delete",
        data
    })
}
//音频上传获取音频接口

export function uppost(data){
    //get 请求=》params
    return create({
        url:"/audio/up/",
        method:"post",
        data
    })
}
//获取音频历史记录详情：
export function xqget(params){
    //get 请求=》params
    return create({
        url:"/audio/xq/",
        method:"get",
        params:params
    })
}
//获取上传对话音频历史记录：
export function obtainget(params){
    //get 请求=》params
    return create({
        url:"/audio/obtain/",
        method:"get",
        params:params
    })
}
//删除音频历史记录：
export function obtaindelete(data){
    //get 请求=》params
    return create({
        url:"/audio/obtain/",
        method:"delete",
        data
    })
}
//绘图
export function drawpost(data){
    //get 请求=》params
    return create({
        url:"/pro/draw/",
        method:"post",
        data
    })
}
//绘图历史
export function drawhistoryget(params){
    //get 请求=》params
    return create({
        url:"/pro/draw/history/",
        method:"get",
        params:params
    })
}
//绘图历史详情
export function drawhistorygetxq(params){
    //get 请求=》params
    return create({
        url:"/pro/draw/history/xq/",
        method:"get",
        params:params
    })
}
//删除图片历史记录：
export function historydrawdelete(data){
    //get 请求=》params
    return create({
        url:"/pro/draw/history/",
        method:"delete",
        data
    })
}

//修改ls
export function historyPUT(data){
    //get 请求=》params
    return create({
        url:"/his/history/",
        method:"PUT",
        data
    })
}
//卡通合影历史
export function imghisget(params){
    //get 请求=》params
    return create({
        url:"/avatar/img/his/",
        method:"get",
        params:params
    })
}
//卡通合影历史删除：
export function imghisdelete(data){
    //get 请求=》params
    return create({
        url:"/avatar/img/his/",
        method:"delete",
        data
    })
}
//魔法贴纸获取历史数据：
export function histzget(params){
    //get 请求=》params
    return create({
        url:"/avatar/tz/his/",
        method:"get",
        params:params
    })
}
//卡通合影历史删除：
export function zthisdelete(data){
    //get 请求=》params
    return create({
        url:"/avatar/tz/his/",
        method:"delete",
        data
    })
}
// cat获取对话记录

export function toolsget3(params){
    //get 请求=》params
    return create({
        url:"/patrick/history/",
        method:"get",
        params:params
    })
}
// cat获取对话记录详情

export function historyxq(params){
    //get 请求=》params
    return create({
        url:"/patrick/history/xq/",
        method:"get",
        params:params
    })
}
//删除对话记录
export function deldelete(data){
    //get 请求=》params
    return create({
        url:"/patrick/history/del/",
        method:"delete",
        data
    })
}
//邀请码
export function userinvite(params){
    //get 请求=》params
    return create({
        url:"/invite/user/invite/",
        method:"get",
        params:params
    })
}
// /登陆主页邀请人的头像和信息
export function byuserget(params){
    //get 请求=》params
    return create({
        url:"/invite/by/user/",
        method:"get",
        params:params
    })
}
// 价格
export function orderssub(params){
    //get 请求=》params
    return create({
        url:"/orders/sub/",
        method:"get",
        params:params
    })
}
// 套餐
export function ordersgetsub(params){
    //get 请求=》params
    return create({
        url:"/orders/get/sub/",
        method:"get",
        params:params
    })
}

// 订单
export function orderorders( params){
    //get 请求=》params
    return create({
        url:"/orders/get/sub/",
        method:"get",
      
        params
    })
}
// 订单详情支付
export function ordersgetorder(data){
    //get 请求=》params
    return create({
        url:"/pay/wechat/",
        method:"post",
        data
    })
}
// 支付
export function paywechat( data){
    //get 请求=》params
    return create({
        url:"/orders/order/",
        method:"post",
        data
    })
}
// 循环请求
export function getordersts( params){
    //get 请求=》params
    return create({
        url:"/orders/get/order/sts/",
        method:"get",
        params:params
    })
}
// 循环访问是否支付
export function ordersgetordersts( params){
    //get 请求=》params
    return create({
        url:"/orders/get/order/sts/",
        method:"get",
        params:params
    })
}
// 邀请列表
export function inviteinvite( params){
    //get 请求=》params
    return create({
        url:"/invite/invite/",
        method:"get",
        params:params
    })
}
// 用户列表
export function getorders( params){
    //get 请求=》params
    return create({
        url:"/orders/get/order/list/",
        method:"get",
        params:params
    })
}

// 激活码
export function invitebycode(  data){
    //get 请求=》params
    return create({
        url:"/invite/by/code/",
        method:"post",
        data
    })
}
// 添加支付宝账号
export function invitealipay(  data){
    //get 请求=》params
    return create({
        url:"/invite/alipay/",
        method:"post",
        data
    })
}
//删除表单
export function delorder(data){
    //get 请求=》params
    return create({
        url:"/orders/del/order/",
        method:"delete",
        data
    })
}

// 提现接口
export function invitesharelink( params){
    //get 请求=》params
    return create({
        url:"/invite/sharelink/",
        method:"get",
        params:params
    })
}
// 单词配音接口
export function voiceword(data){
    //get 请求=》params
    return create({
        url:"/voice/word/",
        method:"post",
        data
    })
}
// 单词配音历史接口
export function voicewordget(params){
    //get 请求=》params
    return create({
        url:"/voice/word/",
        method:"get",
        params
    })
}

//删除单词配音历史接口
export function voiceworddelete(data){
    //get 请求=》params
    return create({
        url:"/voice/word/",
        method:"delete",
        data
    })
}



// 文章配音接口
export function voicearticle(data){
    //get 请求=》params
    return create({
        url:"/voice/article/",
        method:"post",
        data
    })
}

// 文章配音历史接口
export function voicearticleget(params){
    //get 请求=》params
    return create({
        url:"/voice/article/",
        method:"get",
        params
    })
}

//删除文章配音历史接口
export function voicearticledelete(data){
    //get 请求=》params
    return create({
        url:"/voice/article/",
        method:"delete",
        data
    })
}

// 文本导出单词接口
export function newtoossession(data){
    //get 请求=》params
    return create({
        url:"/newtoos/session/",
        method:"post",
        data
    })
}

// 文本导出单词历史接口
export function newtoossessionget(params){
    //get 请求=》params
    return create({
        url:"/newtoos/session/",
        method:"get",
        params
    })
}

//删除文本导出单词历史接口
export function newtoossessiondelete(data){
    //get 请求=》params
    return create({
        url:"/newtoos/session/",
        method:"delete",
        data
    })
}
// 文本导出word文档接口
export function newtoosdocxpost(data){
    //get 请求=》params
    return create({
        url:"/newtoos/docx/",
        method:"post",
        data
    })
}
//反复调用故事配图
export function prodrawget(params){
    //get 请求=》params
    return create({
        url:"/pro/draw/get/",
        method:"get",
        params
    })
}
//反复调用卡通合影
export function avatarimgget(params){
    //get 请求=》params
    return create({
        url:"/avatar/img/get/",
        method:"get",
        params
    })
}
//反复调用卡通合影
export function avatartzget(params){
    //get 请求=》params
    return create({
        url:"/avatar/tz/get/",
        method:"get",
        params
    })
}
//反复调用卡通合影
export function vipp(params){
    //get 请求=》params
    return create({
        url:"/user/vip/",
        method:"get",
        params
    })
}
//时间
export function user(params){
    //get 请求=》params
    return create({
        url:"/user/second/",
        method:"get",
        params
    })
}
// 获取卷妮历史     
export function patrickjanehistory(params){
    //get 请求=》params
    return create({
        url:"/patrick/jane/history/",
        method:"get",
        params
    })
}
//  获取卷妮历史详情    
export function patrickjanehistoryxq(params){
    //get 请求=》params
    return create({
        url:"/patrick/jane/history/xq/",
        method:"get",
        params
    })
}
//删除卷妮历史   
export function janehistorydel(data){
    //get 请求=》params
    return create({
        url:"/patrick/jane/history/del/",
        method:"delete",
        data
    })
}
//  获取svg历史详情    
export function funcsvgget(params){
    //get 请求=》params
    return create({
        url:"/func/svg/",
        method:"get",
        params
    })
}
//删除卷妮历史   
export function workmapdelete(data){
    //get 请求=》params
    return create({
        url:"/work/map/",
        method:"delete",
        data
    })
}
//  获取svg历史详情    
export function mapxqwork(params){
    //get 请求=》params
    return create({
        url:"/work/mapxq/",
        method:"get",
        params
    })
}
//  获取svg历史详情    
export function mapxqsvgmap(params){
    //get 请求=》params
    return create({
        url:"/work/svgmap/",
        method:"get",
        params
    })
}






//删除pet历史   
export function workpetdelete(data){
    //get 请求=》params
    return create({
        url:"/work/pet/",
        method:"delete",
        data
    })
}

//  获取pet历史  
export function petwork(params){
    //get 请求=》params
    return create({
        url:"/work/pet/",
        method:"get",
        params
    })
}
export function petaitext(params){
    //get 请求=》params
    return create({
        url:"/work/aitext/",
        method:"get",
        params
    })
}
//删除pet历史   
export function workketdelete(data){
    //get 请求=》params
    return create({
        url:"/work/ket/",
        method:"delete",
        data
    })
}
export function woraitextdelete(data){
    //get 请求=》params
    return create({
        url:"/work/aitext/",
        method:"delete",
        data
    })
}

//  获取pet历史  
export function ketwork(params){
    //get 请求=》params
    return create({
        url:"/work/ket/",
        method:"get",
        params
    })
}
//  获取pet历史详情
export function petxq(params){
    //get 请求=》params
    return create({
        url:"/work/petxq/",
        method:"get",
        params
    })
}
export function aitextxq(params){
    //get 请求=》params
    return create({
        url:"/work/aitextxq/",
        method:"get",
        params
    })
}
//  获取ket历史详情
export function ketxq(params){
    //get 请求=》params
    return create({
        url:"/work/ketxq/",
        method:"get",
        params
    })
}

    //get 请求=newtoos
export function newtoossqc(params){
    return create({
        url:"/newtoos/sqc/",
        method:"get",
        params
    })
}








//删除KET历史   
export function ketworkmapdelete(data){
    //get 请求=》params
    return create({
        url:"/work/map/",
        method:"delete",
        data
    })
}

//  获取KET历史详情    
export function ketmapxqwork(params){
    //get 请求=》params
    return create({
        url:"/work/mapxq/",
        method:"get",
        params
    })
}
//  获取KET历史详情    
export function ketmapxqsvgmap(params){
    //get 请求=》params
    return create({
        url:"/work/svgmap/",
        method:"get",
        params
    })
}
// 文本导出word文档接口
export function newtoosdocxmap3post(data){
    //get 请求=》params
    return create({
        url:"chat-map3",
        method:"post",
        data
    })
}



//删除K电子文档归纳总结
export function ketworkdocdelete(data){
    //get 请求=》params
    return create({
        url:"/work/doc/",
        method:"delete",
        data
    })
}


//  获取电子文档归纳总结历史   
export function ketmapxqdoc(params){
    //get 请求=》params
    return create({
        url:"/work/doc/",
        method:"get",
        params
    })
}
//  获取电子文档归纳总结历史详情    
export function ketmdocxqsvgmap(params){
    //get 请求=》params
    return create({
        url:"/work/docxq/",
        method:"get",
        params
    })
}
//音频
export function mp(data){
    //get 请求=》params
    return create({
        url:"/work/mp/",
        method:"post",
        data
    })
}
//微信

export function wechat(params){
    //get 请求=》params
    return create({
        url:"/extra/wechat/",
        method:"get",
        params
    })
}












// 后台营销：
//  有效注册用户接口
export function wholeadmin(params){
    //get 请求=》params
    return create({
        url:"/extra/whole/",
        method:"get",
        params
    })
}
//  过去24小时有效注册用户接口
export function adminnew(params){
    //get 请求=》params
    return create({
        url:"/extra/new/",
        method:"get",
        params
    })
}

// 充值用户与未充值用户接口
export function adminvip(params){
    //get 请求=》params
    return create({
        url:"/extra/vip/",
        method:"get",
        params
    })
}

// 20小时即将到期用户接口
export function adminexp(params){
    //get 请求=》params
    return create({
        url:"/extra/exp/",
        method:"get",
        params
    })
}

// 通过邮箱查询用户的有效邀请的人数
export function adminsearch(params){
    //get 请求=》params
    return create({
        url:"/extra/search/",
        method:"get",
        params
    })
}
// 认证问题
export function volumen(params){
    //get 请求=》params
    return create({
        url:"/vlo/volumen/",
        method:"get",
        params
    })
}

// 认证问题
export function vlovolumen(data){
    //get 请求=》params
    return create({
        url:"/vlo/volumen/",
        method:"post",
        data
    })
}

// 邮箱用户添加
export function addvip(params){
    //get 请求=》params
    return create({
        url:"extra/add/vip/",
        method:"get",
        params
    })
}
// 邮箱用户添加时间
export function adminvippost(data){
    //get 请求=》params
    return create({
        url:"extra/add/vip/ ",
        method:"post",
        data
    })
}

// 邮箱用户删除时间
export function vipdelete(data){
    //get 请求=》params
    return create({
        url:"extra/add/vip/ ",
        method:"delete",
        data
    })
}






//ppt    生成内容
export function generatepost(data){
    //get 请求=》params
    return create({
        url:"/ppt/generate/ ",
        method:"post",
        data
    })
}
// ppt  获取内容
export function generateget(params){
    //get 请求=》params
    return create({
        url:"/ppt/generate/",
        method:"get",
        params
    })
}
// ppt 历史记录
export function ppthistoryget(params){
    //get 请求=》params
    return create({
        url:"/ppt/history/",
        method:"get",
        params
    })
}

// 邮箱用户删除时间
export function pptgeneratedelete(data){
    //get 请求=》params
    return create({
        url:"/ppt/generate/",
        method:"delete",
        data
    })
}
// ppt 用户
export function admintime(params){
    //get 请求=》params
    return create({
        url:"/extra/time/",
        method:"get",
        params
    })
}
// ppt 用户
export function script(params){
    //get 请求=》params
    return create({
        url:"/extra/script/",
        method:"get",
        params
    })
}
export function thank(params){
    //get 请求=》params
    return create({
        url:"/extra/thank/",
        method:"get",
        params
    })
}
//邀请用户
export function invite(params){
    //get 请求=》params
    return create({
        url:"/extra/invite/",
        method:"get",
        params
    })
}


//AIPOSU
export function aipost(data){
    //get 请求=》params
    return create({
        url:"/func/content/",
        method:"post",
        data
    })
}

//支付POSU
export function content(data){
    //get 请求=》params
    return create({
        url:"/orders/wchat/order/public/",
        method:"post",
        data
    })
}

//用户的chat使用排序前20条
export function token(params){
    //get 请求=》params
    return create({
        url:"/extra/token/",
        method:"get",
        params
    })
}
//单词默写连读
export function condastent(data){
    //get 请求=》params
    return create({
        url:"/func/mp3/",
        method:"post",
        data
    })
}
//单词默写连读
export function condastentget(params){
    //get 请求=》params
    return create({
        url:"/func/mp3/",
        method:"get",
        params
    })
}
export function condastentdelete(data){
    //get 请求=》params
    return create({
        url:"/func/mp3/",
        method:"delete",
        data
    })
}

export function funcnum(params){
    //get 请求=》params
    return create({
        url:"/func/num/",
        method:"get",
        params
    })
}
export function functsk(params){
    //get 请求=》params
    return create({
        url:"/func/tsk/",
        method:"get",
        params
    })
}

export function functskpost(data){
    //get 请求=》params
    return create({
        url:"/func/tsk/",
        method:"post",
        data
    })
}
//发送短信
export function usersendpost(data){
    //get 请求=》params
    return create({
        url:"/user/send/",
        method:"post",
        data
    })
}
//发送短信
export function usercodepost(data){
    //get 请求=》params
    return create({
        url:"/user/code/",
        method:"post",
        data
    })
}
//生成儿歌
export function musicgeneratepost(data){
    //get 请求=》params
    return create({
        url:"/music/generate/",
        method:"post",
        data
    })
}
// 获取儿歌
export function musicgeneratgete(params){
    //get 请求=》params
    return create({
        url:"/music/generate/",
        method:"get",
        params
    })
}
    //# 获取儿歌历史记录
    export function musicuserget(params){
        //get 请求=》params
        return create({
            url:"/music/user/",
            method:"get",
            params
        })
    }
    // 获取儿歌详情
export function musicusergpost(data){
    //get 请求=》params
    return create({
        url:"/music/user/",
        method:"post",
        data
    })
}

//删除儿歌
export function musicuserdelete(data){
    //get 请求=》params
    return create({
        url:"/music/user/",
        method:"delete",
        data
    })
}
//删除儿歌
export function musicseniorget(params){
    //get 请求=》params
    return create({
        url:"/music/senior/",
        method:"get",
        params
    })
}
export function musicnumget(params){
    //get 请求=》params
    return create({
        url:"/music/num/",
        method:"get",
        params
    })
}
//手机号
export function musicnumgetnumd(params){
    //get 请求=》params
    return create({
        url:"/music/yz/",
        method:"get",
        params
    })
}
// 验证码
export function musicnumgetnumdss(data){
    //get 请求=》params
    return create({
        url:"/music/get/",
        method:"post",
        data
    })
}
// 验证
export function musicnumgetaanumd(data){
    //get 请求=》params
    return create({
        url:"/music/gpt/",
        method:"post",
        data
    })
}
export function musicsend(data){
    //get 请求=》params
    return create({
        url:"/music/send/",
        method:"post",
        data
    })
}
export function musicnumgeasdtnumd(params){
    //get 请求=》params
    return create({
        url:"/music/look/",
        method:"get",
        params
    })
}
//10分钟刷新
export function musidddaa(params){
    //get 请求=》params
    return create({
        url:"/user/inspect/",
        method:"get",
        params
    })
}
export function funcfunc(data){
    //get 请求=》params
    return create({
        url:"/func/multiple/",
        method:"post",
        data
    })
}

   //# 获取儿歌历史记录
   export function funcmultipleget(params){
    //get 请求=》params
    return create({
        url:"/func/multiple/",
        method:"get",
        params
    })
}

//删除儿歌
export function uncmultidelete(data){
//get 请求=》params
return create({
    url:"/func/multiple/",
    method:"delete",
    data
})
}

export function funcpropost(data){
    //get 请求=》params
    return create({
        url:"/func/pro/",
        method:"post",
        data
    })
}

   //# 获取儿歌历史记录
   export function funcproget(params){
    //get 请求=》params
    return create({
        url:"/func/pro/",
        method:"get",
        params
    })
}

//删除儿歌
export function prodelete(data){
//get 请求=》params
return create({
    url:"/func/pro/",
    method:"delete",
    data
})
}
//闪卡
export function funnukpost(data){
    //get 请求=》params
    return create({
        url:"/func/nuk/",
        method:"post",
        data
    })
}
export function funngptkpost(data){
    //get 请求=》params
    return create({
        url:"/func/gpt/",
        method:"post",
        data
    })
}
export function funngptkpostee(data){
    //get 请求=》params
    return create({
        url:"/sticker/gpt/",
        method:"post",
        data
    })
}

//闪卡详情和全部历史记录

   export function funcprogetd(params){
    //get 请求=》params
    return create({
        url:"/func/nuk/",
        method:"get",
        params
    })
}


//删除闪卡
export function funcprodeletea(data){
//get 请求=》params
return create({
    url:"/func/nuk/",
    method:"delete",
    data
})
}


export function funcprogptget(params){
    //get 请求=》params
    return create({
        url:"/func/gpt/",
        method:"get",
        params
    })
}
export function funcprognumptget(params){
    //get 请求=》params
    return create({
        url:"/func/nuk/num/",
        method:"get",
        params
    })
}
export function sotrygenerate(data){
    //get 请求=》params
    return create({
        url:"/sotry/generate/",
        method:"post",
        data
    })
}

export function sotrygeneratgete(params){
    //get 请求=》params
    return create({
        url:"/sotry/generate/",
        method:"get",
        params
    })
}
export function sotrygeneratgeteid(params){
    //get 请求=》params
    return create({
        url:"/sotry/generate/id/",
        method:"get",
        params
    })
}


//删除闪卡
export function sotrygeneratedelete(data){
//get 请求=》params
return create({
    url:"/sotry/generate/",
    method:"delete",
    data
})
}

export function newtoosasbpost(data){
    //get 请求=》params
    return create({
        url:"/newtoos/asb/",
        method:"post",
        data
    })
}


export function newtoosasbget(params){
    //get 请求=》params
    return create({
        url:"/newtoos/asb/",
        method:"get",
        params
    })
}


//删除闪卡
export function newtoosasbdelete(data){
//get 请求=》params
return create({
    url:"/newtoos/asb/",
    method:"delete",
    data
})
}


//手机登录发短信
export function phonesendpost(data){
    //get 请求=》params
    return create({
        url:"/phone/send/",
        method:"post",
        data
    })
}


//手机登录
export function phoneloginpost(data){
    //get 请求=》params
    return create({
        url:"/phone/login/",
        method:"post",
        data
    })
}
export function studentgeneratepost(data){
    //get 请求=》params
    return create({
        url:"/student/generate/",
        method:"post",
        data
    })
}
export function studentputpost(data){
    //get 请求=》params
    return create({
        url:"/student/put/",
        method:"post",
        data
    })
}


export function studentgenerateget(params){
    //get 请求=》params
    return create({
        url:"/student/generate/",
        method:"get",
        params
    })
}


export function studentgeneratePUT(data){
    //get 请求=》params
    return create({
        url:"/student/generate/",
        method:"PUT",
        data
    })
}

export function funcceget(params){
    //get 请求=》params
    return create({
        url:"/func/ce/",
        method:"get",
        params
    })
}
export function funccepost(data){
    //get 请求=》params
    return create({
        url:"/func/ce/",
        method:"post",
        data
    })
}
export function funccegdet(params){
    //get 请求=》params
    return create({
        url:"/func/ce2/",
        method:"get",
        params
    })
}
export function funccegiddet(params){
    //get 请求=》params
    return create({
        url:"/audio/id/",
        method:"get",
        params
    })
}
export function funccepdost(data){
    //get 请求=》params
    return create({
        url:"/func/ce2/",
        method:"post",
        data
    })
}
export function funcstick(data){
    //get 请求=》params
    return create({
        url:"/func/stick/",
        method:"post",
        data
    })
}
export function studenthistoryxq(params){
    //get 请求=》params
    return create({
        url:"/student/history/xq/",
        method:"get",
        params
    })
}
export function studenthistory(params){
    //get 请求=》params
    return create({
        url:"/student/history/",
        method:"get",
        params
    })
}
//修改口令
export function userpspost(data){
    //get 请求=》params
    return create({
        url:"/user/ps/",
        method:"post",
        data
    })
}
//添加服务群：
export function userroompost(data){
    //get 请求=》params
    return create({
        url:"/user/room/",
        method:"post",
        data
    })
}

//获取口令
export function userpsget(params){
    //get 请求=》params
    return create({
        url:"/user/ps/",
        method:"get",
        params
    })
}
//获取服务群：
export function userroomget(params){
    //get 请求=》params
    return create({
        url:"/user/room/",
        method:"get",
        params
    })
}
//修改群名字
export function userroomPUT(data){
    //get 请求=》params
    return create({
        url:"/user/room/",
        method:"PUT",
        data
    })
}



export function toolsget31(params){
    //get 请求=》params
    return create({
        url:"/patrick/ai/history/",
        method:"get",
        params:params
    })
}
// cat获取对话记录详情

export function historyxq1(params){
    //get 请求=》params
    return create({
        url:"/patrick/ai/history/xq/",
        method:"get",
        params:params
    })
}
export function funcmultiplehc(params){
    //get 请求=》params
    return create({
        url:"/func/multiple/hc/",
        method:"get",
        params:params
    })
}
export function agentcodeyz(params){
    //get 请求=》params
    return create({
        url:"/agent/code/yz/",
        method:"get",
        params:params
    })
}
export function ordersroll(params){
    //get 请求=》params
    return create({
        url:"/orders/roll/",
        method:"get",
        params:params
    })
}

//删除对话记录
export function deldelete1(data){
    //get 请求=》params
    return create({
        url:"/patrick/ai/history/del/",
        method:"delete",
        data
    })
}

export function studenttopic(data){
    //get 请求=》params
    return create({
        url:"/student/topic/",
        method:"post",
        data
    })
}

export function studenttopicget(params){
    //get 请求=》params
    return create({
        url:"/student/topic/",
        method:"get",
        params:params
    })
}

export function studenttopicdelete(data){
    //get 请求=》params
    return create({
        url:"/student/topic/",
        method:"delete",
        data
    })
}


export function fcework(params){
    //get 请求=》params
    return create({
        url:"/work/fce/",
        method:"get",
        params
    })
}
//  获取pet历史详情
export function fcexq(params){
    //get 请求=》params
    return create({
        url:"/work/fcexq/",
        method:"get",
        params
    })
}

export function wfceetdelete(data){
    //get 请求=》params
    return create({
        url:"/work/fce/",
        method:"delete",
        data
    })
}
export function avatarstickerspost(data){
    //get 请求=》params
    return create({
        url:"/avatar/stickers/",
        method:"post",
        data
    })
}
export function avatarstickersget(params){
    //get 请求=》params
    return create({
        url:"/avatar/stickers/",
        method:"get",
        params
    })
}
export function avatarstickersgethis(params){
    //get 请求=》params
    return create({
        url:"/avatar/stickers/his/",
        method:"get",
        params
    })
}
export function musicfilgete(params){
    //get 请求=》params
    return create({
        url:"/music/file/",
        method:"get",
        params
    })
}
export function usergettime(params){
    //get 请求=》params
    return create({
        url:"/user/time/",
        method:"get",
        params
    })
}
export function avatarstickehideletesrs(data){
    //get 请求=》params
    return create({
        url:"/avatar/stickers/his/",
        method:"delete",
        data
    })
}
export function musicfildeletee(data){
    //get 请求=》params
    return create({
        url:"/music/file/",
        method:"delete",
        data
    })
}

export function studenttpostosendpic(data){
    //get 请求=》params
    return create({
        url:"/student/topic/send/",
        method:"post",
        data
    })
}
export function voicpostesuper(data){
    //get 请求=》params
    return create({
        url:"/voice/super/",
        method:"post",
        data
    })
}


export function voicsupereget(params){
    //get 请求=》params
    return create({
        url:"/voice/super/",
        method:"get",
        params
    })
}
export function supevdeleteoicer(data){
    //get 请求=》params
    return create({
        url:"/voice/super/",
        method:"delete",
        data
    })
}








//生成单词卡接口：

export function func1gppostt(data){
    //get 请求=》params
    return create({
        url:"/sticker/generate/",
        method:"post",
        data
    })
}

//查找图片接口：
export function fungetcgpt1(params){
    //get 请求=》params
    return create({
        url:"/sticker/gpt/",
        method:"get",
        params
    })
}
//查看历史记录接口：
export function funcget1gpgett(params){
    //get 请求=》params
    return create({
        url:"/sticker/generate/",
        method:"get",
        params
    })
}
//查看历史记录详情接口：
export function func1gptgetget(params){
    //get 请求=》params
    return create({
        url:"/sticker/generate/",
        method:"get",
        params
    })
}
//剩余次数接口：
export function func1gptasdfgetget(params){
    //get 请求=》params
    return create({
        url:"/sticker/num/",
        method:"get",
        params
    })
}
//删除历史记录接口：
export function func1dgptelete(data){
    //get 请求=》params
    return create({
        url:"/sticker/generate/",
        method:"delete",
        data
    })
}

export function wordgeneratgete(params){
    //get 请求=》params
    return create({
        url:"/word/generate/",
        method:"get",
        params
    })
}
//删除历史记录接口：
export function wordgeneratedelete(data){
    //get 请求=》params
    return create({
        url:"/word/generate/",
        method:"delete",
        data
    })
}


export function wordgenerapostte(data){
    //get 请求=》params
    return create({
        url:"/word/generate/",
        method:"post",
        data
    })
}
export function separatesepostparate(data){
    //get 请求=》params
    return create({
        url:"/separate/separate/",
        method:"post",
        data
    })
}
export function separatesgeteparate(params){
    //get 请求=》params
    return create({
        url:"/separate/separate/",
        method:"get",
        params
    })
}
export function cvocabulpostaryard(data){
    //get 请求=》params
    return create({
        url:"/vocabulary/card/",
        method:"post",
        data
    })
}

export function vocabularcgetardy(params){
    //get 请求=》params
    return create({
        url:"/vocabulary/card/",
        method:"get",
        params
    })
}
export function vocabularcgetahisrdy(params){
    //get 请求=》params
    return create({
        url:"/vocabulary/card/his/",
        method:"get",
        params
    })
}
export function vocabularycdeleteard(data){
    //get 请求=》params
    return create({
        url:"/vocabulary/card/his/",
        method:"delete",
        data
    })
}
export function ordersmupostsic(data){
    //get 请求=》params
    return create({
        url:"/orders/music/",
        method:"post",
        data
    })
}
export function avatarwordillustrationspost(data){
    //get 请求=》params
    return create({
        url:"/avatar/wordillustrations/",
        method:"post",
        data
    })
}
export function avatarwordillustrationsreviserpost(data){
    //get 请求=》params
    return create({
        url:"/avatar/wordillustrations/reviser/",
        method:"post",
        data
    })
}

export function avatarwordillustrationsget(data){
    //get 请求=》params
    return create({
        url:"/avatar/wordillustrations/",
        method:"get",
        data
    })
}
// #子账号购买
export function orderssonpost(data){
    //get 请求=》params
    return create({
        url:"/orders/son/",
        method:"post",
        data
    })
}
// #子账号续费：
export function ordersxfpost(data){
    //get 请求=》params
    return create({
        url:"/orders/xf/",
        method:"post",
        data
    })
}
// #子账号登录：
export function usersonlogipostn(data){
    //get 请求=》params
    return create({
        url:"/user/son/login/",
        method:"post",
        data
    })
}
// #子账号登录：
export function ordersnumpost(data){
    //get 请求=》params
    return create({
        url:"/orders/num/",
        method:"post",
        data
    })
}
// #子账号登录：
export function avatartaskwordpost(data){
    //get 请求=》params
    return create({
        url:"/avatar/task-word/",
        method:"post",
        data
    })
}
export function avatarwordillustrationschangepost(data){
    //get 请求=》params
    return create({
        url:"/avatar/wordillustrations/change/",
        method:"post",
        data
    })
}
// 查看子账号：
export function usersonget(data){
    //get 请求=》params
    return create({
        url:"/user/son/",
        method:"get",
        data
    })
}
// 修改子账号：
export function usersonPUT(data){
    //get 请求=》params
    return create({
        url:"/user/son/",
        method:"PUT",
        data
    })
}

// 查看子账号：
export function funcmdget(params){
    //get 请求=》params
    return create({
        url:"/func/md/",
        method:"get",
        params:params
    })
}
export function funcmdnumget(params){
    //get 请求=》params
    return create({
        url:"/func/md/num/",
        method:"get",
        params:params
    })
}
export function funcmdinfoget(params){
    //get 请求=》params
    return create({
        url:"/func/md/info/",
        method:"get",
        params:params
    })
}

export function apimdpost(data){
    //get 请求=》params
    return create({
        url:"/api/md/",
        method:"post",
        data
    })
}
export function procustopostm(data){
    //get 请求=》params
    return create({
        url:"func/pro/custom/",
        method:"post",
        data
    })
}
export function funcmp3custompost(data){
    //get 请求=》params
    return create({
        url:"func/mp3/custom/",
        method:"post",
        data
    })
}
export function funcadasdkajsompost(data){
    //get 请求=》params
    return create({
        url:"/voice/custom/",
        method:"post",
        data
    })
}
export function jsadfhafgas(data){
    //get 请求=》params
    return create({
        url:"/audio/custom/",
        method:"post",
        data
    })
}