import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
import "element-plus/dist/index.css";
// import { ref, onMounted, onBeforeUnmount } from 'vue';

// // 存储特效元素的引用
// const effectElements = ref([]);

// // 处理点击效果
// const createClickEffect = (event) => {
//   const effectElement = document.createElement('span');
//   effectElement.classList.add('click-effect');

//   // 使用 clientX 和 clientY 作为点击效果的位置
//   const { clientX, clientY } = event;

//   // 设置特效位置，减去元素的半径让其居中
//   effectElement.style.left = `${clientX - 10}px`;
//   effectElement.style.top = `${clientY - 10}px`;

//   // 添加到 DOM
//   document.body.appendChild(effectElement);
//   effectElements.value.push(effectElement);

//   // 在动画结束后移除特效元素
//   setTimeout(() => {
//     effectElement.remove();
//     effectElements.value = effectElements.value.filter(el => el !== effectElement);
//   }, 1000); // 1秒后移除
// };

// onBeforeUnmount(() => {
//   // 移除所有的特效元素
//   effectElements.value.forEach(el => el.remove());
// });

export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view)]);
    };
  }
};